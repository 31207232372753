import dayjs from 'dayjs';

// APP CONSTANT
export const APP_NAME = 'ASPYRE2';

// UI constant
export const HEADER_HIGHT = 82;

const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export const TABLE_HEIGHT = {
    SMALL: 500,
    MEDIUM: 'calc(100vh - 560px)',
    LARGE: 'calc(100vh - 320px)'
};

export default LAYOUT_CONST;

export const gridSpacing = 3;
export const drawerWidth = 320;
export const appDrawerWidth = 320;

export const AUTO_CUTOFF_DAYS = [
    { key: 7, value: 7 },
    { key: 10, value: 10 },
    { key: 14, value: 14 }
];

export const NOTIFICATION_DURATION = 2000;

// Common
export const EVENT_TYPES = [
    {
        id: 1,
        name: 'Evaluation',
        code: 'evaluation',
        group: 'therapy'
    },
    {
        id: 2,
        name: 'Treatment',
        code: 'treatment',
        group: 'therapy'
    },
    {
        id: 3,
        name: 'PDC',
        code: 'pdc',
        group: 'school'
    },
    {
        id: 4,
        name: 'ADDT',
        code: 'addt',
        group: 'school'
    },
    {
        id: 5,
        name: 'ITP',
        code: 'itp',
        group: 'school'
    }
];

export const getEventTypeById = (id) => EVENT_TYPES.find((type) => type.id === id).name;

export const getEventTypeByCode = (code) => EVENT_TYPES.find((type) => type.code === code).name;

export const THERAPY_EVENTS = {
    EVALUATION: 1,
    TREATMENT: 2
};

export const VISIT_TYPES = {
    EVALUATION: 1,
    TREATMENT: 0,
    ACTIVITY: 3
};

export const EVENT_IDS = {
    EVALUATION: 1,
    TREATMENT: 1,
    ACTIVITY: 3
};

// Therapy
export const THERAPIES = {
    pt: 1,
    st: 2,
    ot: 3,
    dt: 4
};

export const SERVICES = [
    {
        id: 1,
        name: 'Physical Therapy',
        shortName: 'PT'
    },
    {
        id: 2,
        name: 'Speech Therapy',
        shortName: 'ST'
    },
    {
        id: 3,
        name: 'Occupational Therapy',
        shortName: 'OT'
    },
    {
        id: 4,
        name: 'Developmental Therapy',
        shortName: 'DT'
    }
];

export const getServiceNameById = (id) => SERVICES.find((service) => service.id === id)?.name;

export const THERAPY_MIN_TIME = dayjs().set('hour', 6).set('minute', 0).set('second', 0);
export const THERAPY_MAX_TIME = dayjs().set('hour', 22).set('minute', 59).set('second', 59);

// Others
export const SERVICE_PDC = 5;
export const SERVICE_ADDT = 6;
export const SERVICE_IEP = 7;
export const SERVICE_PDC_ANCILLARY = 8;
export const SERVICE_ADDT_ANCILLARY = 9;

export const STATUSID = 1;

export const ACTIVITY_EIDT_STAFFING_CONF = 2;

export const ACTIVITY_ADMINISTRATIVE_BILLING = 24;

export const ACTIVITY_ANNUAL_REVIEW = 100;

export const THERAPY_STATUS = {
    SCHEDULED: 9,
    ONGOING: 8,
    COMPLETED: 7
};

export const THERAPY_STATUS_LIST = [
    { id: 7, name: 'Scheduled' },
    { id: 8, name: 'In Progress' },
    { id: 9, name: 'Completed' }
];

// PDC
export const CLASS = {
    pdc: 5,
    addt: 6
};

export const SCHEDULED_COLORS = {
    5: '#ec9c3d',
    6: '#9E1E9E'
};

export const BUSINESS_TYPE_LOCATION_ID = 4;
export const PDC_TYPE_LOCATION_ID = 5;
export const SCHOOL_TYPE_LOCATION_ID = 6;
export const ADDT_TYPE_LOCATION_ID = 7;

export const INVENTORY_STATUS = {
    AVAILABLE: { id: 15, color: '#66bb6a', status: 'AVAILABLE' },
    UNAVAILABLE: { id: 16, color: '#9e9e9e', status: 'UNAVAILABLE' },
    'IN USE': { id: 17, color: '#ffa726', status: 'IN USE' }
};

export const EMPLOYEE_LICENSE_EXPIRATION_TIMELINE = 30;
export const NOTIFICATION_PANEL_DIMENSIONS = [450, 550]; // [w, h]

export const LIST_LIMIT = 15;

export const LARGE_LIST_LIMIT = 50;

// Status constants
export const PDC_STATUS = {
    ABSENT: 1,
    CLASSROOM_ONGOING: 2,
    ST_ONGOING: 3,
    PT_ONGOING: 4,
    OT_ONGOING: 5,
    OTHERS_ONGOING: 6,
    COMPLETED: 7,
    ONGOING: 8,
    SCHEDULED: 9,
    IN_PROGRESS: 10,
    NOSHOW: 11,
    SESSION_START: 12,
    SESSION_END: 13,
    PRESENT: 14
};

export const STATUS = [
    { id: 1, name: 'Absent' },
    { id: 14, name: 'Present' },
    { id: 41, name: 'Refused' },
    { id: 42, name: 'Unavailable' }
];

export const CLIENT_SWITCH_EXCLUDE = ['id', 'sid', 'tid', 'stid'];
export const EMAIL_CLIENT_TYPE_LIST = { id: 1, name: 'client' };
export const EMAIL_INDIVIDUAL_TYPE_LIST = { id: 3, name: 'individual' };
export const EMAIL_INTERNAL_TYPE_LIST = { id: 2, name: 'internal' };

export const MAIL_SEEN_STATUS = { id: 19, name: 'SEEN' };
export const MAIL_UNSEEN_STATUS = { id: 18, name: 'UNSEEN' };

export const GLOBAL_DATE_FORMAT = 'MM/DD/YYYY';
export const GLOBAL_DATE_FORMAT_DFNS = 'MM/dd/yyyy';

export const GLOBAL_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';

export const GLOBAL_TIME_FORMAT = 'hh:mm A';

export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';
export const PAYLOAD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const BATCH_STATUS_CREATED = 21;

export const BATCH_STATUS_PAID = 32;

export const BATCH_STATUS = {
    21: { id: 21, color: '#4caf50', status: 'CREATED' },
    22: { id: 22, color: '#2196f3', status: 'SUBMITTED' },
    23: { id: 23, color: '#ff9800', status: 'REJECTED' },
    24: { id: 24, color: '#ffeb3b', status: 'ACKNOWLEDGED' },
    32: { id: 32, color: '#4caf50', status: 'PAID' },
    26: { id: 26, color: '#424242', status: 'DRAFT' }
};

export const PATIENT_INVOICE_STATUS = {
    10: { id: 10, color: '#2196f3', status: 'IN PROGRESS' },
    32: { id: 32, color: '#4caf50', status: 'PAID' },
    33: { id: 33, color: '#ffeb3b', status: 'READY TO INVOICE' },
    34: { id: 34, color: '#ffeb3b', status: 'READY TO CLAIM' },
    35: { id: 35, color: '#ffeb3b', status: 'BATCHED' },
    36: { id: 36, color: '#ffeb3b', status: 'SENT TO CLEARING HOUSE' },
    37: { id: 37, color: '#ffeb3b', status: 'INVOICED' },
    43: { id: 43, color: '#4caf50', status: 'PAID(INVOICE)' },
    23: { id: 23, color: '#f59b3e', status: 'REJECTED' },
    24: { id: 24, color: '#ff9818', status: 'ACKNOWLEDGED' },
    44: { id: 44, color: '#ffeb3b', status: 'NO BILL' }
};

export const ACTIVITY_LOGS = {
    CATEGORY_EIDT: 1,
    CATEGORY_SUPERVISOR: 2,
    CATEGORY_CONFERENCE: 3,
    CONFERENCE_SERVICES: [1, 2, 3],
    EIDT_PAYABLE_MIN: 15
};

export const DOCUMENT_TYPES = {
    DMS_640: 6,
    IEP: 9
};

export const PRESCRIPTION_STATUS = {
    DRAFT: [25, 26],
    IN_PROGRESS: [27, 28],
    COMPLETED: [29],
    NEW: [27],
    CANCELLED: [30]
};

export const PHYSICIAN_ROLE_ID = 20;

export const TO_BE_SIGNED_PRESCRIPTION_STATUS_ID = 27;
export const SIGNED_PRESCRIPTION_STATUS_ID = 29;

export const CARE_CORDINATOR_ROLE_ID = 4;

export const THERAPIST_ROLE_ID = 3;
export const ASSISTANT_THERAPIST_ROLE_ID = 2;

export const LOCATION_INVOICE_STATUS = {
    FULLY_PAID: 39
};

export const NOTIFICATION = {
    UNSIGNED_COUNTS_EVAL: 3,
    UNSIGNED_COUNTS_EVAL_SUPERVISOR: 13,
    UNSIGNED_COUNTS_TREATMENT: 8,
    UNSIGNED_COUNTS_TREATMENT_SUPERVISOR: 14,
    UNSIGNED_COUNTS_ITP: 9,
    UNSIGNED_COUNTS_ACTIVITY: 17
};

export const NOTIFICATION_LABEL = {
    UNSIGNED_COUNTS_EVAL: 'NOTIFICATION_UNSIGNED_COUNTS_EVAL',
    UNSIGNED_COUNTS_EVAL_SUPERVISOR: 'NOTIFICATION_UNSIGNED_COUNTS_EVAL_SUPERVISOR',
    UNSIGNED_COUNTS_TREATMENT: 'NOTIFICATION_UNSIGNED_COUNTS_TREATMENT',
    UNSIGNED_COUNTS_TREATMENT_SUPERVISOR: 'NOTIFICATION_UNSIGNED_COUNTS_TREATMENT_SUPERVISOR',
    UNSIGNED_COUNTS_ITP: 'NOTIFICATION_UNSIGNED_COUNTS_ITP',
    UNSIGNED_COUNTS_ACTIVITY: 'NOTIFICATION_UNSIGNED_COUNTS_ACTIVITY'
};

export const SCHOOL_SERVICES = [SERVICE_PDC, SERVICE_ADDT, SERVICE_IEP, SERVICE_PDC_ANCILLARY, SERVICE_ADDT_ANCILLARY];

// changable constants
export const PHYSICIAN_CLIENT = Number(process.env.REACT_APP_PHYSICIAN_CLIENT);
export const PHYSICIAN_LOCATION = Number(process.env.REACT_APP_PHYSICIAN_LOCATION);
export const CLIENT_CYNERGY_ID = Number(process.env.REACT_APP_CLIENT_CYNERGY_ID);

export const IDLE_TIMEOUT_DURATION = process.env.REACT_APP_AUTO_LOGOUT_DURATION ? Number(process.env.REACT_APP_AUTO_LOGOUT_DURATION) : 120;

export const ITP_TYPES = {
    THERAPY: 'therapy',
    PDC: 'pdc'
};

export const PENDING_RANGE = [
    { key: 1, label: '0 - 10', min: 0, max: 10 },
    { key: 2, label: '10 - 50', min: 10.01, max: 50 },
    { key: 3, label: '50 - 100', min: 50.01, max: 100 },
    { key: 4, label: '100 - 200', min: 100.01, max: 200 },
    { key: 5, label: '200 - Above', min: 200.01 }
];

export const SERVICE_STATUS = [23, 24, 32, 35, 36, 37, 38, 41, 43, 44];

export const GETLOCALTIMEZONE = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const BILLING_TYPE = [
    { key: '1', name: 'Primary', value: 'primary' },
    { key: '2', name: 'Secondary', value: 'secondary' }
];
